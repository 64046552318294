<template>
    <section>
        <div class="container">
            <TitleBar title="İş Emri Kayıtları" :modi="modi" />

            <b-field class="columns box" label="Filtre Alanları" grouped group-multiline>
                <b-field class="column is-3" label="ID">
                    <b-input v-model="productionorders.filters.id"></b-input>
                </b-field>
                <b-field class="column is-6" label="Açıklama">
                    <b-input v-model="productionorders.filters.name"></b-input>
                </b-field>
                <b-field class="column is-3" label="Malzeme Kodu">
                    <MaterialAutofill v-model="productionorders.filters.material" />
                </b-field>
                <b-field class="column is-3" label="Iş Merkezi">
                    <b-input v-model="productionorders.filters.workcenter"></b-input>
                </b-field>
                <b-field class="column is-3" label="Plan Başlangıç">
                    <b-datepicker placeholder="Tarih aralığı seçin.." v-model="productionorders.filters.planstart" range> </b-datepicker>
                </b-field>
                <b-field class="column is-3" label="Plan Bitiş">
                    <b-datepicker placeholder="Tarih aralığı seçin.." v-model="productionorders.filters.planend" range> </b-datepicker>
                </b-field>
                <b-field class="column is-3" label="Durum">
                    <b-select placeholder="Seçiniz.." v-model="productionorders.filters.status" expanded>
                        <option value="Açık">Açık</option>
                        <option value="Üretimde">Üretimde</option>
                        <option value="Askıda">Askıda</option>
                        <option value="Tamamlanmış">Tamamlanmış</option>
                        <option value="İptal">İptal</option>
                    </b-select>
                </b-field>
            </b-field>

            <b-field class="columns box mt-2" label="İş Emri Tablosu" grouped group-multiline>
                <b-field class="column is-12" grouped group-multiline>
                    <b-button class="column" type="is-info is-light" icon-left="magnify" label="Ara" @click="productionorders_read" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="plus" label="Yeni" @click="productionorders_new" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="content-copy" label="Ref. Al" @click="productionorders_ref" disabled />
                    <b-button class="column ml-1" type="is-info is-light" icon-left="eye" label="Görüntüle" :disabled="!productionorders.selected.id" @click="productionorders_show" />
                    <b-button class="column ml-1" type="is-warning is-light" icon-left="pencil" label="Düzenle" :disabled="!productionorders.selected.id" @click="productionorders_edit" />
                    <b-button class="column ml-1" type="is-danger is-light" icon-left="delete" label="Sil" :disabled="!productionorders.selected.id" @click="productionorders_delete" />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="printer" label="Yazdır" @click="productionorders_print" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Içe Aktar" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" @click="productionorders_export" disabled />
                </b-field>

                <b-table
                    class="column is-12"
                    :data="productionorders.data"
                    :total="productionorders.total"
                    :mobile-cards="false"
                    :current-page.sync="productionorders.page"
                    :selected.sync="productionorders.selected"
                    :sort-multiple-data.sync="productionorders.sorts"
                    :loading="$apollo.queries.readProductionOrders.loading"
                    @dblclick="productionorders_show"
                    @sort="productionorders_sort"
                    @sorting-priority-removed="productionorders_nosort"
                    backend-pagination
                    backend-sorting
                    sort-multiple
                    striped
                    hoverable
                    narrowed
                    paginated
                    pagination-simple
                >
                    <template slot="empty">
                        <div class="is-12 has-text-centered">
                            İş emri kaydı bulunamadı.
                        </div>
                    </template>

                    <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-4) }}</b-table-column>
                    <b-table-column field="name" label="Açıklama" v-slot="props" sortable>{{ props.row.name }}</b-table-column>
                    <b-table-column field="material.code" label="Malzeme Kodu" v-slot="props">{{ props.row.material.code }}</b-table-column>
                    <b-table-column field="material.name" label="Malzeme Adı" v-slot="props">{{ props.row.material.name }}</b-table-column>
                    <b-table-column field="quantity" label="Miktar (KG)" v-slot="props" sortable>{{ props.row.quantity }} KG</b-table-column>
                    <b-table-column field="quantity" label="Onaylanan (KG)" v-slot="props" sortable>{{ props.row.confirms.reduce((a, c) => a + c.quantity, 0) }} KG</b-table-column>
                    <b-table-column field="workplace" label="İş Merkezi" v-slot="props" sortable>{{ props.row.workplace }}</b-table-column>
                    <b-table-column field="planstart" label="Plan Başlangıç" v-slot="props" sortable>{{
                        new Date(props.row.planstart).toLocaleDateString("tr-TR", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric"
                        })
                    }}</b-table-column>
                    <b-table-column field="planend" label="Plan Bitiş" v-slot="props" sortable>{{
                        new Date(props.row.planend).toLocaleDateString("tr-TR", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric"
                        })
                    }}</b-table-column>
                    <b-table-column field="status" label="Durum" v-slot="props" sortable>
                        <b-tag :type="statusType(props.row.status)">
                            {{ props.row.status }}
                        </b-tag></b-table-column
                    >
                </b-table>
            </b-field>
        </div>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";
import MaterialAutofill from "@/components/MaterialAutofill.vue";

export default {
    name: "ProductionOrderTable",
    components: {
        TitleBar,
        MaterialAutofill
    },
    data: () => ({
        modi: "",
        productionorders: {
            page: 1,
            take: 20,
            total: 9999,
            data: [],
            sorts: [],
            filters: {
                material: {},
                planstart: [],
                planend: []
            },
            selected: {}
        }
    }),
    computed: {
        statusType() {
            return (status) =>
                status == "Açık"
                    ? "is-danger is-light"
                    : status == "Üretimde"
                    ? "is-info is-light"
                    : status == "Askıda"
                    ? "is-warning is-light"
                    : status == "Kapalı"
                    ? "is-success is-light"
                    : status == "İptal"
                    ? "is-light"
                    : "is-light";
        }
    },
    mounted() {
        this.productionorders_read();
    },
    methods: {
        productionorders_read() {
            this.$apollo.queries.readProductionOrders.start();
            this.$apollo.queries.readProductionOrders.refetch();
        },
        productionorders_new() {
            this.$router.push({ path: "/uretim/isemri/ekle" });
        },
        productionorders_ref() {
            this.$router.push({
                path: "/uretim/isemri/ekle",
                query: { id: this.productionorders.selected.id, modi: "ref" }
            });
        },
        productionorders_show() {
            this.$router.push({
                path: "/uretim/isemri/detay",
                query: { id: this.productionorders.selected.id, modi: "show" }
            });
        },
        productionorders_edit() {
            this.$router.push({
                path: "/uretim/isemri/detay",
                query: { id: this.productionorders.selected.id, modi: "edit" }
            });
        },
        productionorders_delete() {},
        productionorders_print() {},
        productionorders_export() {},
        productionorders_sort(field, order) {
            this.productionorders_nosort(field);
            this.productionorders.sorts.push({ field, order });
        },
        productionorders_nosort(field) {
            this.productionorders.sorts.forEach((e, i, a) => {
                if (e.field == field) a.splice(i, 1);
            });
        }
    },
    apollo: {
        readProductionOrders: {
            query: gql`
                query readProductionOrders($config: String) {
                    readProductionOrders(config: $config) {
                        id
                        name
                        status
                        material {
                            id
                            class
                            code
                            name
                        }
                        workplace
                        quantity
                        planstart
                        planend
                        confirms {
                            id
                            quantity
                        }
                    }
                }
            `,
            variables() {
                var sorts = [];
                this.productionorders.sorts.forEach((e) => sorts.push({ column: e.field, order: e.order }));
                for (var filter in this.productionorders.filters) if (!this.productionorders.filters[filter]) delete this.productionorders.filters[filter];

                return {
                    config: JSON.stringify({
                        sorts,
                        take: this.productionorders.take,
                        filters: this.productionorders.filters,
                        skip: (this.productionorders.page - 1) * this.productionorders.take
                    })
                };
            },
            update(data) {
                this.productionorders.data = data.readProductionOrders;
                console.log(this.productionorders.data.configs);
            }
        }
    }
};
</script>
